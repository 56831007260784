import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import './App.css';
import { useState } from "react";
import { Feedback } from './components/Navbar/Feedback';
import { RefundPolicy } from './components/Policies/RefundPolicy';
import { PrivacyPolicy } from './components/Policies/PrivacyPolicy';
import { TermsOfService } from './components/Policies/TermsOfService';
import { Catalog } from './components/Navbar/Catalog';
import { AboutMe } from './components/Navbar/AboutMe';
import { Contact } from './components/Navbar/Contact';
import { Navbar } from "./components/Navbar/Navbar";
import { Footer } from "./components/Footer/Footer";
import { ProductPage } from "./components/Product/ProductPage";
import { Register } from "./components/UserLogin/Register";
import { PasswordRecover } from "./components/UserLogin/PasswordRecover";
import { ResetPassword } from "./components/UserLogin/ResetPassword";
import { UserDetails } from "./components/UserProfile/UserDetails";
import { UserOrders } from "./components/UserProfile/UserOrders";
import { Checkout } from "./components/Checkout/Checkout";
import { Receipt } from "./components/Checkout/Receipt";
import { ProductCreate } from "./components/Admin/ProductCreate";
import { ProductEdit } from "./components/Admin/ProductEdit";
import { OrderBoard } from "./components/Admin/OrderBoard";
import { createContext } from "react";

export const AuthContext = createContext(null);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div className="App">
      <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated}}>
      <Router>
        <Navbar />
        <Routes>
            <Route path='/' element={<Catalog/>} />
            <Route path='/aboutme' element={<AboutMe/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/product/:id' element={<ProductPage/>} />
            <Route path='/register' element={<Register/>} />
            <Route path='/password-recover' element={<PasswordRecover/>} />
            <Route path='/password-reset/:token' element={<ResetPassword/>} />
            <Route path='/checkout' element={<Checkout/>} />
            <Route path='/receipt' element={<Receipt/>} />
            <Route path='/profile' element={<UserDetails/>} />
            <Route path='/orders' element={<UserOrders/>} />
            <Route path='/product-create' element={<ProductCreate/>} />
            <Route path='/product-edit/:id' element={<ProductEdit/>} />
            <Route path='/order-board' element={<OrderBoard/>} />
            <Route path='/feedback' element={<Feedback/>} />
            <Route path='/refund-policy' element={<RefundPolicy/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            <Route path='/terms-of-service' element={<TermsOfService/>} />
          </Routes>
          <Footer />
        </Router>
        </AuthContext.Provider>
    </div>
  );
}

export default App;
