import React, { useEffect, useState, useContext } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../App';
import axiosInstance from '../Interceptor/axiosConfig';
import { Modal } from 'react-bootstrap';

export const OrderBoard = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [showSupport, setShowSupport] = useState(false);
    const handleCloseOrderDetails = () => setShowOrderDetails(false);
    const handleShowOrderDetails = () => setShowOrderDetails(true);
    const handleCloseSupport = () => setShowSupport(false);
    const handleShowSupport = () => setShowSupport(true);
    const [orderId, setOrderId] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [showLogout, setShowLogout] = useState(false);
    const handleCloseLogout = () => setShowLogout(false);
    const handleShowLogout = () => setShowLogout(true);
    const [status, setStatus] = useState({
        status: '',
    });
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const role = localStorage.getItem('role');

    useEffect(() => {
        if (role === "admin") {
            if (isAuthenticated) {
                fetchOrders();
            }
        } else {
            navigate("/");
        }
    }, [isAuthenticated, role, navigate]);

    const fetchOrders = async () => {
        try {
            const response = await axiosInstance.get(`/order/all`);
            setOrders(response.data.orders);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setLoading(false);
        }
    };

    const handleLogout = async () => {
        try {
            await axiosInstance.post('/logout');
            localStorage.removeItem('authToken');
            localStorage.removeItem('role');
            setIsAuthenticated(false);
            handleCloseLogout();
            navigate('/');
        } catch (err) {
            console.error("Error during logout", err);
        }
    };

    const handleChange = (event) => {
        setStatus({
            status: event.target.value,
            _method: 'PUT',
        });
    };

    const getDetails = async (event) => {
        const orderId = event.target.getAttribute('name');
        try {
            const response = await axiosInstance.get(`/order/${orderId}`);
            setOrderDetails(response.data.order);
            setUserDetails(response.data.user);
            console.log(response.data);
            setOrderId(event.target.getAttribute('name'));
            handleShowOrderDetails();
        } catch (err) {
            console.error("Error during fetch", err);
        }
    };

    const getSupport = async (event) => {
        const orderId = event.target.getAttribute('name');
        try {
            const response = await axiosInstance.get(`/order/${orderId}`);
            setOrderDetails(response.data.order);
            setUserDetails(response.data.user);
            console.log(response.data);
            handleShowSupport();
        } catch (err) {
            console.error("Error during fetch", err);
        }
    };

    const updateStatus = async () => {
        try {
            const response = await axiosInstance.post(`/order/${orderId}/status`, status);
            console.log(response.data);
            handleCloseOrderDetails();
            fetchOrders();
        } catch (err) {
            console.error("Error during fetch", err);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredOrders = orders.filter(order =>
        order.order_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.status.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return null;
    }

  return (
    <div className='row m-0 g-0 p-0'>
        <div className='col-3 mt-5'>
            <div className='d-flex flex-column align-items-center mt-1'>
                <Link className='custom-product-btn btn my-1 text-start rounded rounded-3 d-flex align-items-center' to="/profile">
                    <Icon.PersonCircle size={28} />
                    <span className='ms-2'>Profile</span>
                </Link>
                <Link className='custom-product-btn btn my-1 text-start op-selected rounded rounded-3 d-flex align-items-center' to="/orders">
                    <Icon.BoxSeam size={28} />
                    <span className='ms-2'>Orders</span>
                </Link>
                <div className="row g-0 mx-3 justify-content-center dotted-separator-2">
                    <div className="col-auto"></div>
                </div>
                <Link className='custom-product-btn btn my-1 text-start rounded rounded-3 d-flex align-items-center' onClick={handleShowLogout}><Icon.BoxArrowInRight size={28}/><span className='ms-2'>Sign out</span></Link >
            </div>
        </div>
        <div className="col-8">
            <div className="row g-0 justify-content-between">
                <div className='col-auto mt-3'>
                    <span className='form-title ms-4'>Orders</span>
                </div>
            </div>
            <div className="row g-0 mt-5 justify-content-end">
                <div className="col-auto">
                    <div className='search-component d-flex justify-content-center'>
                    <input
                                className='custom-search-bar rounded rounded-3 p-2 ps-3'
                                type='text'
                                placeholder='Search'
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                    </div>
                </div>
            </div>
            <div className="row g-0 mt-1">
                <div className="col-12 separator-2"></div>
            </div>
            {filteredOrders.map((order, orderIndex) => (
            <div key={orderIndex}>
                <div className="row g-0 mt-3 justify-content-center">
                    <div className="col-12">
                        <div className="row g-0 px-3 justify-content-between">
                            <div className="col-3 d-flex justify-content-center align-items-center">
                            {order.products.map((product, productIndex) => (
                                <div className="product-image-order rounded rounded-4 me-2" key={productIndex}>
                                <img src={`https://smollnia-api.shop/public/${product.image}`} alt={product.image} className="preview-image product-image-order rounded rounded-4" />
                                </div>
                            ))}
                            </div>
                            <div className="col-9 d-flex align-items-center justify-content-between">
                                <div className="product-basket-details ms-4 d-flex flex-column">
                                    <span className='product-name-basket'>Order:</span>
                                    <span className='product-price-basket'>#{order.order_code}</span>
                                </div>
                                <div className="product-basket-details ms-4 d-flex flex-column">
                                    <span className='product-name-basket'>Purchased on:</span>
                                    <span className='product-price-basket'>{new Date(order.created_at).toLocaleDateString()}</span>
                                </div>
                                <div className="product-basket-details ms-4 d-flex flex-column">
                                    <span className='product-name-basket'>Total:</span>
                                    <span className='product-price-basket'>EUR {order.total_price}€</span>
                                </div>
                                <div className="product-basket-details ms-4 d-flex flex-column">
                                    <div className={`status py-1 d-flex justify-content-center rounded rounded-3 ${order.status.toLowerCase()}`}>
                                        <span className='product-name-basket'>{order.status}</span>
                                    </div>
                                </div>
                                <div className="order-buttons ms-4 d-flex flex-column">
                                    <button className='btn order-btn order-details-btn rounded rounded-3' name={order.id} onClick={getDetails} >View details</button>
                                    <button className='btn order-btn order-support-btn rounded rounded-3' name={order.id} onClick={getSupport}>Support</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-0 mt-3 mb-3 justify-content-center">
                    <div className="col-11 dotted-separator-3"></div>
                </div>
            </div>
            ))}
            <div className="row g-0 mb-5 justify-content-center">
                <div className="col-auto pagination d-flex align-items-center rounded rounded-4">
                    <input type="button" className="btn btn-product-quantity mx-1" value="<" />
                    <span className='product-quantity-basket mx-1'>1</span>
                    <input type="button" className="btn btn-product-quantity mx-1" value=">" />
                </div>
            </div>
            <Modal show={showOrderDetails} onHide={handleCloseOrderDetails} centered className="g-0">
                <div className='order-details-modal px-2 py-3 rounded rounded-3'>
                    <div className='row g-0 justify-content-end me-2'>
                        <div className='col-auto'>
                            <Icon.XLg size={32} onClick={handleCloseOrderDetails}  style={{ cursor: 'pointer' }}/>
                        </div>
                    </div>
                    <div className="row g-0 mt-1 justify-content-center">
                        <div className="col-auto">
                            <h1 className="modal-title fs-2" id="exampleModalLabel">Order details</h1>
                        </div>
                    </div>
                    <div className="row g-0 mt-4 justify-content-center">
                        <div className="col-auto">
                            <span className='ms-2 detail-title'>Order number: {orderDetails.order_code}</span>
                        </div>
                    </div>
                    <div className="row g-0 mt-4">
                        <div className="col-1 ms-5 d-flex justify-content-center">
                            <Icon.Truck size={30}></Icon.Truck>
                        </div>
                        <div className="col-auto d-flex flex-column">
                            <span className='ms-2 detail-title'>Order for:</span>
                            <span className='ms-2 mt-2'>{userDetails.name} {userDetails.last_name} {userDetails.surname}</span>
                            <span className='ms-2'>{orderDetails.address} {orderDetails.apartment}</span>
                            <span className='ms-2'>{orderDetails.postal_code} {orderDetails.city}, {orderDetails.province}, {orderDetails.country}</span>
                        </div>
                    </div>
                    <div className="row g-0 mt-4">
                        <div className="col-1 ms-5 d-flex justify-content-center">
                    </div>
                    <div className="col-auto d-flex flex-column">
                        <span className='ms-2 detail-title'>Status:</span>
                        <select className='ms-2 mt-2 textfield-small rounded rounded-3 px-2 status-select' value={status.status} onChange={handleChange}>
                            <option value="Pending">Pending</option>
                            <option value="Sent">Sent</option>
                            <option value="Completed">Completed</option>
                            <option value="Drafted">Drafted</option>
                        </select>
                    </div>
                </div>
                <div className="row g-0 mt-4">
                    <div className="col-1 ms-5 d-flex justify-content-center">
                        <Icon.CreditCard size={30}></Icon.CreditCard>
                    </div>
                    <div className="col-auto d-flex flex-column">
                        <span className='ms-2 detail-title'>Payment method:</span>
                        <span className='ms-2 mt-2'>{orderDetails.payment_method}</span>
                    </div>
                </div>
                <div className="row g-0 mt-4">
                    <div className="col-1 ms-5 d-flex justify-content-center">
                        <Icon.CashStack size={30}></Icon.CashStack>
                    </div>
                    <div className="col-auto d-flex flex-column">
                        <span className='ms-2 detail-title'>Total:</span>
                        <span className='ms-2 mt-2'>€{orderDetails.total_price} EUR</span>
                    </div>
                </div>
                <div className="row justify-content-center mt-5 mb-3">
                    <div className='col-4 d-flex justify-content-center'>
                        <span className='btn print-btn rounded rounded-3' onClick={updateStatus}>Update</span>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal show={showSupport} onHide={handleCloseSupport} centered className="g-0">
            <div className='order-details-modal px-2 py-3 rounded rounded-3'>
                <div className='row g-0 justify-content-end me-2'>
                    <div className='col-auto'>
                        <Icon.XLg size={32} onClick={handleCloseSupport}  style={{ cursor: 'pointer' }}/>
                    </div>
                </div>
                <div className="row g-0 mt-1 justify-content-center">
                    <div className="col-auto">
                        <h1 className="modal-title fs-2" id="exampleModalLabel">Contact customer</h1>
                    </div>
                </div>
                <div className='row g-0 mt-4 justify-content-center'>
                    <div className='col-9 d-flex flex-column'>
                        <span className='ms-2'>
                            Order
                        </span>
                        <input type="text" name="order_code" id="order_code" value={orderDetails.order_code} className='textfield-small rounded rounded-3 p-2 ps-3 disabled' disabled/>
                    </div>
                </div>
                <div className="row g-0 justify-content-between">
                    <div className='col-1'></div>
                        <div className="col-4 mt-3 me-1 d-flex flex-column">
                            <span className='ms-2'>
                                Customer name
                            </span>
                            <input type="text" name="name" id="name" value={userDetails.name} className='textfield-small rounded rounded-3 p-2 ps-3 disabled' disabled/>
                        </div>
                        <div className="col-4 mt-3 d-flex flex-column ms-3">
                            <span className='ms-2'>
                                Pronouns
                            </span>
                            <input type="text" name="pronouns" id="pronouns" value={userDetails.pronouns} className='textfield-small rounded rounded-3 p-2 ps-3 disabled' disabled/>
                        </div>
                        <div className='col-1'></div>
                    </div>
                    <div className="row g-0 justify-content-center">
                        <div className="row g-0 justify-content-center">
                            <div className='col-9 mt-2 d-flex flex-column '>
                                <span className='ms-2'>
                                    *Title
                                </span>
                                <input type="text" name="title" id="title" className='textfield-small rounded rounded-3 p-2 ps-3'/>
                            </div>
                        </div>
                    <div className="row g-0 justify-content-center mt-4">
                        <div className='col-9 d-flex flex-column'>
                            <span className='ms-2'>
                                *Description
                            </span>
                            <textarea name="description" id="description" className='textarea rounded rounded-4 p-2 ps-3' />
                        </div>
                    </div>
                    <div className="row g-0 justify-content-end me-3">
                        <div className='col-3 d-flex me-4'>
                            <span className='required-fields mt-1'>
                                *Required fields
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4 mb-2">
                        <div className='col-4 d-flex justify-content-center'>
                            <input type='button' className='btn order-support-btn order-btn large-btn rounded rounded-3' value='Send' onClick={handleCloseSupport}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal show={showLogout} onHide={handleCloseLogout} centered className="g-0">
       <div className='order-details-modal px-2 py-3 rounded rounded-3'>
           <div className='row g-0 justify-content-end me-2'>
               <div className='col-auto'>
                   <Icon.XLg size={32} onClick={handleCloseLogout}  style={{ cursor: 'pointer' }}/>
               </div>
           </div>
           <div className="row g-0 mt-1 justify-content-center">
               <div className="col-auto">
                   <h1 className="modal-title fs-2 " id="exampleModalLabel">Logout</h1>
               </div>
           </div>
           <div className="row g-0 mt-4 mb-5 justify-content-center">
               <div className="col-9 mt-3 d-flex justify-content-center">

                       <span className='ms-2'>You are about to log out, are you sure?</span>

               </div>
           </div>
           <div className="row justify-content-center mt-4 mb-3">
               <div className='col-10 d-flex justify-content-between'>
                <input type='button' className='btn order-details-btn order-btn rounded rounded-3' value='Cancel' onClick={handleCloseLogout} />
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleLogout} />
               </div>
           </div>
           </div>
       </Modal>
    </div>
  </div>
  )
}
