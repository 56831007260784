import React from 'react'
import telegram from '../../img/telegram.png';
import instagram from '../../img/instagram.png';
import twitter from '../../img/twitter.png'
import { Link} from "react-router-dom";

export const Footer = () => {
  return (

      <footer className='footer row g-0 justify-content-center py-3'>
        <div className='col-3 justify-content-center mt-3'>
          <div className='social-media-title d-flex justify-content-center'>
            <span>
              Social media
            </span>
          </div>
          <div className='social-media-group d-flex justify-content-center mt-3'>
            <Link className='social-media-option d-flex align-items-center' to={'https://t.me/smollnias'}>
              <img className='me-2' src={telegram} alt='tlg-icon'/>
              <span>
                Telegram
              </span>
            </Link>
            <Link className='social-media-option d-flex align-items-center' to={'https://x.com/smollnia'}>
              <img className='me-2' src={twitter} alt='twi-icon'/>
              <span>
                Twitter
              </span>
            </Link>
            <Link className='social-media-option d-flex align-items-center' to={'https://www.instagram.com/smollnia/'}>
              <img className='me-2' src={instagram} alt='inst-icon'/>
              <span>
                Instagram
              </span>
            </Link>
          </div>
        </div>
        <div className='policies col-3 d-flex flex-column text-end mt-1'>

              <Link className="custom-footer-btn text-decoration-none" to="/feedback" role="button">
                Feedback
              </Link>
              <Link className="custom-footer-btn text-decoration-none" to="/refund-policy">
                Refund Policy
              </Link>
              <Link className="custom-footer-btn text-decoration-none" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="custom-footer-btn text-decoration-none" to="/terms-of-service">
                Terms of service
              </Link>

        </div>
      </footer>


  )
}
