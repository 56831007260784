import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import beetles from '../../img/beetles.png';
import { Link } from 'react-router-dom';

const BasketModal = ({ show, handleClose }) => {
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      setCartItems(storedCartItems);
      calculateTotal(storedCartItems);
    }, [show]);

    const calculateTotal = (items) => {
      const total = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
      setTotal(total);
    };

    const handleIncrement = (index) => {
      const newCartItems = [...cartItems];
      const item = newCartItems[index];
      console.log(item.stock);
      if (item.quantity < item.stock) {
        item.quantity += 1;
        setCartItems(newCartItems);
        localStorage.setItem('cartItems', JSON.stringify(newCartItems));
        calculateTotal(newCartItems);
      }
    };

    const handleDecrement = (index) => {
      const newCartItems = [...cartItems];
      const item = newCartItems[index];

      if (item.quantity > 1) {
        item.quantity -= 1;
      } else {
        newCartItems.splice(index, 1);
      }

      setCartItems(newCartItems);
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
      calculateTotal(newCartItems);
    };


  return (
    <Modal show={show} onHide={handleClose} className="modal-dialog-slideout g-0">
        <div className="basket-modal-container px-2 py-3">
        <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
                <Icon.XLg size={32} onClick={handleClose}  style={{ cursor: 'pointer' }}/>
            </div>
        </div>
        <div className="row g-0 justify-content-center">
            <div className="col-auto">
                <h1 className="modal-title fs-1" id="basketModalLabel">Basket</h1>
            </div>
        </div>
        <div className="row g-0 justify-content-end">
          {cartItems.map((item, index) => (
            <div key={item.id} className="row g-0 mt-5 justify-content-between">
              <div className="col-auto d-flex align-items-center ms-4">
                <div className="product-image-basket rounded rounded-4">
                  <img src={`https://smollnia-api.shop/public/${item.image}`} alt={item.name} className="preview-image product-image-basket rounded rounded-4" />
                </div>
                <div className="product-basket-details ms-4 d-flex flex-column">
                  <span className="product-name-basket">{item.name}</span>
                  <span className="product-price-basket">€{item.price} EUR</span>
                </div>
              </div>
              <div className="col-auto d-flex align-items-center product-basket-quantity me-4">
                <Button variant="outline-secondary" className="btn-product-quantity" onClick={() => handleDecrement(index)}>-</Button>
                <span className="product-quantity-basket">{item.quantity}</span>
                <Button variant="outline-secondary" className="btn-product-quantity" onClick={() => handleIncrement(index)}>+</Button>
              </div>
            </div>
          ))}
        </div>
        <div className="basket-total fixed-bottom">
        <div className="row g-0 mx-3 justify-content-center dotted-separator">
          <div className="col-auto">
            <img src={beetles} alt='Beetles' />
          </div>
        </div>
        <div className="row g-0 mt-2 ms-4">
          <div className="col-auto">
            <div className="basket-total-price">
              <span>TOTAL €{total.toFixed(2)} EUR</span>
            </div>
          </div>
        </div>
        <div className="row g-0 my-5 justify-content-center">
          <div className="col-auto">
            <div className="basket-checkout">
              <Link variant="primary" className={`btn basket-checkout-btn ${cartItems.length === 0 ? 'disabled' : ''}`} onClick={handleClose} to={cartItems.length === 0 ? '#' : '/checkout'}>
                  Checkout
              </Link>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Modal>
  );
};

export default BasketModal;
