import {React, useState, useEffect} from 'react'
import axiosInstance from '../Interceptor/axiosConfig';

import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export const ProductCreate = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [showError, setShowError] = useState(false);
  const handleCloseError = () => setShowError(false);
  const handleShowError = () => setShowError(true);
  const [image, setImage] = useState(null);
  const [image_secondary1, setImageSecondary1] = useState(null);
  const [image_secondary2, setImageSecondary2] = useState(null);
  const [image_secondary3, setImageSecondary3] = useState(null);

  const role = localStorage.getItem('role');

  useEffect(() => {
    if(role === "admin"){

    }
    else{
        navigate("/");
    }

  }, [role, navigate]);

  const [formData, setFormData] = useState({
    name: '',
    price: '',
    type: '',
    stock: '',
    description: '',
    image: null,
    image_secondary1: null,
    image_secondary2: null,
    image_secondary3: null,
  });

  const handleInputChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      switch (imageType) {
        case 'primary':
          setImage(imageUrl);
          setFormData((prevFormData) => ({ ...prevFormData, image: file }));
          break;
        case 'secondary1':
          setImageSecondary1(imageUrl);
          setFormData((prevFormData) => ({ ...prevFormData, image_secondary1: file }));
          break;
        case 'secondary2':
          setImageSecondary2(imageUrl);
          setFormData((prevFormData) => ({ ...prevFormData, image_secondary2: file }));
          break;
        case 'secondary3':
          setImageSecondary3(imageUrl);
          setFormData((prevFormData) => ({ ...prevFormData, image_secondary3: file }));
          break;
        default:
          break;
      }
    } else {
      switch (imageType) {
        case 'primary':
          setImage(null);
          setFormData((prevFormData) => ({ ...prevFormData, image: null }));
          break;
        case 'secondary1':
          setImageSecondary1(null);
          setFormData((prevFormData) => ({ ...prevFormData, image_secondary1: null }));
          break;
        case 'secondary2':
          setImageSecondary2(null);
          setFormData((prevFormData) => ({ ...prevFormData, image_secondary2: null }));
          break;
        case 'secondary3':
          setImageSecondary3(null);
          setFormData((prevFormData) => ({ ...prevFormData, image_secondary3: null }));
          break;
        default:
          break;
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.image) {
      setError('At least one primary image is required');
      handleShowError();
      return;
    }

    const productData = new FormData();
    productData.append('image', formData.image);
    if (formData.image_secondary1) {
      productData.append('image_secondary1', formData.image_secondary1);
    }
    if (formData.image_secondary2) {
      productData.append('image_secondary2', formData.image_secondary2);
    }
    if (formData.image_secondary3) {
      productData.append('image_secondary3', formData.image_secondary3);
    }

    for (const key in formData) {
      if (
        formData[key] &&
        key !== 'image' &&
        key !== 'image_secondary1' &&
        key !== 'image_secondary2' &&
        key !== 'image_secondary3'
      ) {
        productData.append(key, formData[key]);
      }
    }

    try {
      const response = await axiosInstance.post('/product', productData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        handleShowSuccess();
      } else {
        setError("There's been an error creating your product");
        handleShowError();
      }
    } catch (error) {
      setError('Failed to create the product, check that all the required fields are not empty.')
      handleShowError();
    }
  };

  return (
    <div className='row g-0 m-0 p-0 justify-content-center'>
      <div className="row g-0 justify-content-center">
        <div className='col-auto form-title mt-3'>
            <span>Create product</span>
        </div>
      </div>
    <form className='row g-0 justify-content-center' onSubmit={handleSubmit}>
    <div className="col-3 mt-5 mb-5">
      <div className='product-image custom-file-upload rounded rounded-4'>
      <label className="custom-file-upload rounded rounded-4" htmlFor="imageInput">
        {image && <img src={image} alt="Product" className="preview-image product-image rounded rounded-4" /> }
          <input id="imageInput" type="file" name="image" accept="image/*" style={{ display: 'none' }} onChange={(e) => handleInputChange(e, 'primary')} />
        </label>

      </div>
      <div className='row secondary-images mt-4 g-0 p-0'>
        <div className='col-3'>
          <div className='product-image-secondary custom-file-upload-secondary rounded rounded-4'>
          <label className="custom-file-upload-secondary rounded rounded-4">
            {image_secondary1 && <img src={image_secondary1} alt="Product" className="preview-image product-image-secondary rounded rounded-4"/>}
                <input type="file" name="image_secondary1" accept="image/*" style={{ display: 'none' }} onChange={(e) => handleInputChange(e, 'secondary1')}/>
              </label>
          </div>
        </div>
        <div className='col-3'>
          <div className='product-image-secondary custom-file-upload-secondary rounded rounded-4'>
          <label className="custom-file-upload-secondary rounded rounded-4">
          {image_secondary2 && <img src={image_secondary2} alt="Product" className="preview-image product-image-secondary rounded rounded-4"/>}
                <input type="file" name="image_secondary2"  accept="image/*" style={{ display: 'none' }} onChange={(e) => handleInputChange(e, 'secondary2')} />
              </label>
          </div>
        </div>
        <div className='col-3'>
        <div className='product-image-secondary custom-file-upload-secondary rounded rounded-4'>
        <label className="custom-file-upload-secondary rounded rounded-4">
          {image_secondary3 && <img src={image_secondary3} alt="Product" className="preview-image product-image-secondary rounded rounded-4"/> }
              <input type="file" name="image_secondary3" accept="image/*" style={{ display: 'none' }} onChange={(e) => handleInputChange(e, 'secondary3')} />
            </label>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-auto">
            <span className='ms-2 mt-1'>*At least 1 photo is required</span>
        </div>
      </div>

    </div>
    <div className="col-3 mt-5 ms-3 mb-5">
        <div className='row g-0 mt-2 justify-content-start'>
            <div className='col-11 d-flex flex-column'>
            <span className='ms-2'>
                *Product name
            </span>
            <input type="text" name="name" id="name" className='textfield-small rounded rounded-3 p-2 ps-2' value={formData.name} onChange={handleChange}/>
            </div>
        </div>
        <div className='row g-0 mt-2 justify-content-start'>
            <div className='col-11 d-flex flex-column'>
            <span className='ms-2'>
                *Price
            </span>
            <input type="text" name="price" id="price" className='textfield-small rounded rounded-3 p-2 ps-2'value={formData.price} onChange={handleChange}/>
            </div>
        </div>
        <div className='row g-0 mt-2 justify-content-start'>
            <div className='col-11 d-flex flex-column'>
                <span className='ms-2'>
                    *Product Type
                </span>
                <select name="type" id="type" className='textfield-small rounded rounded-3 p-2 ps-2' value={formData.type} onChange={handleChange}>
                    <option value="sticker" >Sticker</option>
                    <option value="keychain">Keychain</option>
                    <option value="pin">Pin</option>
                    <option value="standee">Standee</option>
                    <option value="washi-tape">Washi tape</option>
                    <option value="print">Print</option>
                </select>
            </div>
        </div>
        <div className='row g-0 mt-2 justify-content-start'>
            <div className='col-11 d-flex flex-column'>
                <span className='ms-2'>
                    *Stock
                </span>
                <input type="number" name="stock" id="stock" className='textfield-small rounded rounded-3 p-2 ps-2' value={formData.stock} onChange={handleChange}/>
            </div>
        </div>
        <div className='row g-0 mt-2 justify-content-start'>
            <div className='col-11 d-flex flex-column'>
                <span className='ms-2'>
                    *Description
                </span>
                <textarea name="description" id="description" className='textarea rounded rounded-3 p-2 ps-2' value={formData.description} onChange={handleChange}/>
            </div>
        </div>
      <div className="row g-0 mt-4">
        <div className="col-11 mt-5 d-flex justify-content-end">
          <input type="submit" className="btn mt-3 add-to-cart-btn rounded rounded-3" value="Save" />
        </div>
      </div>
    </div>
    </form>
    <Modal show={showSuccess} onHide={handleCloseSuccess} centered className="g-0">
    <div className='order-details-modal px-2 py-3 rounded rounded-3'>
        <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
                <Icon.XLg size={32} onClick={handleCloseSuccess}  style={{ cursor: 'pointer' }}/>
            </div>
        </div>
        <div className="row g-0 mt-1 justify-content-center">
            <div className="col-auto">
                <h1 className="modal-title fs-2 " id="exampleModalLabel">Success!</h1>
            </div>
        </div>
        <div className="row g-0 mt-4 mb-5 justify-content-center">
            <div className="col-auto mt-3 d-flex justify-content-center">
                <span className='ms-2'>Product was created successfully.</span>
            </div>
        </div>
        <div className="row justify-content-center mt-4 mb-3">
            <div className='col-auto'>
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseSuccess}/>
            </div>
        </div>
        </div>
    </Modal>
    <Modal show={showError} onHide={handleCloseError} centered className="g-0">
    <div className='order-details-modal px-2 py-3 rounded rounded-3'>
        <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
                <Icon.XLg size={32} onClick={handleCloseError}  style={{ cursor: 'pointer' }}/>
            </div>
        </div>
        <div className="row g-0 mt-1 justify-content-center">
            <div className="col-auto">
                <h1 className="modal-title fs-2 " id="exampleModalLabel">ERROR</h1>
            </div>
        </div>
        <div className="row g-0 mt-4 mb-5 justify-content-center">
            <div className="col-9 mt-3 d-flex justify-content-center">
                {error && (
                    <span className='ms-2'>{error}</span>
                )}
            </div>
        </div>
        <div className="row justify-content-center mt-4 mb-3">
            <div className='col-auto'>
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseError}/>
            </div>
        </div>
        </div>
    </Modal>
  </div>
  )
}
