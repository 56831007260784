import React from 'react'

export const RefundPolicy = () => {
  return (
    <div className="row g-0 justify-content-center">
    <div className="col-5">
    <div className='form-title mt-3 d-flex justify-content-center'>
      <span>Refund Policy</span>
    </div>
    <br />
    <br />
    <p>If you’re looking to return or exchange your order for whatever reason, we're here to help!</p><br />
    <p>We offer free returns with the customer’s choice of store credit or refund to the original payment method up to 30 days after the date of purchase when:</p>
    <ul>
      <li>An item arrives damaged.</li>
      <li>You receive item(s) different to what you ordered.</li>
    </ul>
    <br />
    <p>We offer returns for store credit up to 30 days after the date of purchase when:</p>
    <ul>
      <li>Apparel is purchased and doesn’t fit (if we mess up and send you a different size than you ordered, this will be a free return).</li>
    </ul>
    <br />
    <p>Please note the following exceptions to our return policy:</p>
    <ul>
      <li>Discounted items are final and cannot be returned or exchanged.</li>
      <li>Returned items must have tags and/or labels still intact.</li>
      <li>Returned items must have no visible signs of wear or use, unless reason for return is preexisting damage.</li>
    </ul>
    <br />
    <p>To initiate a return, please complete the following steps:</p>
    <br />
    <p>Free Returns:</p>
    <ul>
      <li>Reply to your order confirmation email to request which products you would like to return.</li>
      <li>Print the prepaid return shipping label that you will receive by email.</li>
      <li>Send all items back to us using the label provided.</li>
    </ul>
    <br />
    <p>Other/paid Returns:</p>
    <ul>
      <li>Reply to your order confirmation email to request which products you would like to return.</li>
      <li>When your return is confirmed, you will receive an address to ship your return to.</li>
      <li>Go to the local post office, or a courier of your choice, and ship the package.</li>
    </ul>
    <br />
    <p>We will issue a refund your original payment method once the item(s) are delivered to us.</p>
    <br />
    <br />
    </div>
  </div>
  )
}
