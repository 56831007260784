import {React, useState} from 'react'
import axiosInstance from '../Interceptor/axiosConfig';
import { Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export const PasswordRecover = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [showError, setShowError] = useState(false);
  const handleCloseError = () => setShowError(false);
  const handleShowError = () => setShowError(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/forgot-password', { email });

      handleShowSuccess();
    } catch (error) {
      setError('The email is not registered on this website.');
      handleShowError();
    }
  };
  return (
    <div className='catalog row m-0 g-0 p-0 justify-content-center'>
        <div className='col-auto form-title mt-3'>
            <span>Reset your password</span>
        </div>
        <form onSubmit={handleSubmit}>
        <div className='row g-0 mt-5 justify-content-center'>
          <div className="row g-0 justify-content-center">
                <div className='col-4'>
                    <span className='ms-3'>
                      Enter your email address and we'll send you a link to reset your password.
                    </span>
                </div>
            </div>
            <div className="row g-0 mt-4 justify-content-center">
                <div className='col-4 d-flex flex-column'>
                    <span className='ms-2'>
                      Email registered
                    </span>
                    <input type="email" name="email" id="email" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={(e) => setEmail(e.target.value)}/>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className='col-4 d-flex justify-content-center'>
                    <input type='submit' className='btn basket-checkout-btn rounded rounded-3' value='Send'/>
                </div>
            </div>
        </div>
        </form>
        <Modal show={showSuccess} onHide={handleCloseSuccess} centered className="g-0">
       <div className='order-details-modal px-2 py-3 rounded rounded-3'>
           <div className='row g-0 justify-content-end me-2'>
               <div className='col-auto'>
                   <Icon.XLg size={32} onClick={handleCloseSuccess}  style={{ cursor: 'pointer' }}/>
               </div>
           </div>
           <div className="row g-0 mt-1 justify-content-center">
               <div className="col-auto">
                   <h1 className="modal-title fs-2 " id="exampleModalLabel">Success!</h1>
               </div>
           </div>
           <div className="row g-0 mt-4 mb-5 justify-content-center">
               <div className="col-auto mt-3 d-flex justify-content-center">
                   <span className='ms-2'>Password reset sent successfully.</span>
               </div>
           </div>
           <div className="row justify-content-center mt-4 mb-3">
               <div className='col-auto'>
                   <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseSuccess}/>
               </div>
           </div>
           </div>
       </Modal>
       <Modal show={showError} onHide={handleCloseError} centered className="g-0">
       <div className='order-details-modal px-2 py-3 rounded rounded-3'>
           <div className='row g-0 justify-content-end me-2'>
               <div className='col-auto'>
                   <Icon.XLg size={32} onClick={handleCloseError}  style={{ cursor: 'pointer' }}/>
               </div>
           </div>
           <div className="row g-0 mt-1 justify-content-center">
               <div className="col-auto">
                   <h1 className="modal-title fs-2 " id="exampleModalLabel">ERROR</h1>
               </div>
           </div>
           <div className="row g-0 mt-4 mb-5 justify-content-center">
               <div className="col-9 mt-3 d-flex justify-content-center">
                   {error && (
                       <span className='ms-2'>{error}</span>
                   )}
               </div>
           </div>
           <div className="row justify-content-center mt-4 mb-3">
               <div className='col-auto'>
                   <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseError}/>
               </div>
           </div>
           </div>
       </Modal>
    </div>
  )
}
