import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../Interceptor/axiosConfig';
import * as Icon from 'react-bootstrap-icons';
import { Modal } from 'react-bootstrap';

export const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [stock, setStock] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [hoverImage, setHoverImage] = useState(null);

  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axiosInstance.get(`/product/${id}`);
        setProduct(response.data);
        setStock(response.data.stock);
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const imageBaseUrl = 'https://smollnia-api.shop/public/';

  const handleIncrement = () => {
    if (quantity < stock) {
      setQuantity(prevQuantity => prevQuantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const handleAddToCart = () => {
    if (stock > 0 && quantity <= stock) {
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const existingItemIndex = cartItems.findIndex(item => item.id === product.id);

      const newCartItem = {
        id: product.id,
        name: product.name,
        price: product.price,
        image: product.image,
        quantity: quantity,
        stock: product.stock,
      };

      if (existingItemIndex >= 0) {
        const totalQuantity = cartItems[existingItemIndex].quantity + quantity;
        if (totalQuantity <= stock) {
          cartItems[existingItemIndex].quantity = totalQuantity;
        } else {
          alert('Quantity exceeds available stock');
          return;
        }
      } else {
        cartItems.push(newCartItem);
      }

      localStorage.setItem('cartItems', JSON.stringify(cartItems));
      handleShowSuccess();
      setStock(prevStock => prevStock - quantity);
      setQuantity(1);
    } else {
      alert('Product out of stock or quantity exceeds available stock');
    }
  };

  if (loading) {
    return ;
  }

  if (!product) {
    return ;
  }

  const mainImage = hoverImage || product.image;

  return (
    <div className='row g-0 m-0 p-0 justify-content-center'>
      <div className="col-3 mt-5">
        <div className='product-image rounded rounded-4'>
          <img src={`${imageBaseUrl}${mainImage}`} alt={product.name} className="preview-image product-image rounded rounded-4" />
        </div>
        <div className='row secondary-images mt-4 g-0 p-0'>
          <div className='col-3'>
            {product.image_secondary1 !== null && (
              <img
                src={`${imageBaseUrl}${product.image_secondary1}`}
                alt=""
                className="preview-image product-image-secondary rounded rounded-4"
                onMouseEnter={() => setHoverImage(product.image_secondary1)}
                onMouseLeave={() => setHoverImage(null)}
              />
            )}
          </div>
          <div className='col-3'>
            {product.image_secondary2 !== null && (
              <img
                src={`${imageBaseUrl}${product.image_secondary2}`}
                alt=""
                className="preview-image product-image-secondary rounded rounded-4"
                onMouseEnter={() => setHoverImage(product.image_secondary2)}
                onMouseLeave={() => setHoverImage(null)}
              />
            )}
          </div>
          <div className='col-3'>
            {product.image_secondary3 !== null && (
              <img
                src={`${imageBaseUrl}${product.image_secondary3}`}
                alt=""
                className="preview-image product-image-secondary rounded rounded-4"
                onMouseEnter={() => setHoverImage(product.image_secondary3)}
                onMouseLeave={() => setHoverImage(null)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-3 mt-5 ms-3">
        <div className="row mt-5 g-0">
          <span className='form-title'>{product.name}</span>
        </div>
        <div className="row g-0">
          <span className='product-price'>EUR {product.price}€</span>
        </div>
        <div className="row mt-4 g-0">
          <div className='col-12'>
            <span className='product-description'>{product.description}</span>
          </div>
        </div>
        <div className="row g-0 mt-4">
          <span className='product-stock'>{stock} {product.type}s left</span>
        </div>
        <div className="row mt-3 g-0">
          <div className="col-3 d-flex justify-content-between align-items-center">
            <input type="button" className="btn btn-stock-options" value="-" onClick={handleDecrement} />
            <span className='product-stock'>{quantity}</span>
            <input type="button" className="btn btn-stock-options" value="+" onClick={handleIncrement} />
          </div>
        </div>
        <div className="row g-0 mt-3">
          <div className="col-4">
            <input type="button" className="btn add-to-cart-btn rounded rounded-3" value="Add to cart"
              onClick={handleAddToCart}
              disabled={stock === 0 || quantity > stock}  />
          </div>
        </div>
      </div>
      <Modal show={showSuccess} onHide={handleCloseSuccess} centered className="g-0">
        <div className='order-details-modal px-2 py-3 rounded rounded-3'>
          <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
              <Icon.XLg size={32} onClick={handleCloseSuccess}  style={{ cursor: 'pointer' }}/>
            </div>
          </div>
          <div className="row g-0 mt-1 justify-content-center">
            <div className="col-auto">
              <h1 className="modal-title fs-2 " id="exampleModalLabel">Success!</h1>
            </div>
          </div>
          <div className="row g-0 mt-4 mb-5 justify-content-center">
            <div className="col-auto mt-3 d-flex justify-content-center">
              <span className='ms-2'>The product was added to the basket.</span>
            </div>
          </div>
          <div className="row justify-content-center mt-4 mb-3">
            <div className='col-auto'>
              <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseSuccess}/>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
