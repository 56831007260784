import React, { useEffect, useState, useContext } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Link, useNavigate} from "react-router-dom";
import axiosInstance from '../Interceptor/axiosConfig';
import { AuthContext } from '../../App';
import { Modal } from 'react-bootstrap';


export const UserDetails = (props) => {
    const {setIsAuthenticated} = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => setShowSuccess(true);
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    const [showLogout, setShowLogout] = useState(false);
    const handleCloseLogout = () => setShowLogout(false);
    const handleShowLogout = () => setShowLogout(true);

    const [formData, setFormData] = useState({
        name: '',
        last_name: '',
        surname: '',
        pronouns: '',
        phone: '',
        email: '',
        new_email: '',
        password: '',
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
    });

    const navigate = useNavigate();

    const role = localStorage.getItem('role');

    const fetchUserProfile = async () => {
        try {
            const response = await axiosInstance.get('/user/details');
            setUser(response.data.user);
            setFormData({
                name: response.data.user.name,
                last_name: response.data.user.last_name,
                surname: response.data.user.surname,
                pronouns: response.data.user.pronouns || '',
                phone: response.data.user.phone || '',
                email: response.data.user.email,
                new_email: '',
                password: '',
                current_password: '',
                new_password: '',
                new_password_confirmation: ''
            });
        } catch (err) {
            setError('Failed to fetch user profile.');
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, []);



    const handleChange = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value || '';
        setFormData({ ...formData, [name]: sanitizedValue });
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/user/update-profile', formData);
            console.log(response.data);
            handleShowSuccess();
            fetchUserProfile();
        } catch (err) {
            setError('Failed to update profile, check that all the required fields are not empty.');
            handleShowError();
        }
    };

    const handleUpdateEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/user/update-email', {
                new_email: formData.new_email,
                password: formData.password
              });
            console.log(response.data);
            handleShowSuccess();
            fetchUserProfile();
        } catch (error) {
            setError('Failed to update your email, check that your password is correct.');
            handleShowError();
        }
    };

    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/user/update-password', {
                current_password: formData.current_password,
                new_password: formData.new_password,
                new_password_confirmation: formData.new_password_confirmation,
              });
            console.log(response.data);
            handleShowSuccess();
        } catch (error) {
            if(formData.new_password !== formData.new_password_confirmation){
                setError("Failed to update your password, passwords don't match.");
                handleShowError();
            }
        }
    };

    const handleLogout = async () => {
        try {
            await axiosInstance.post('/logout');
            localStorage.removeItem('authToken');
            localStorage.removeItem('role');
            setIsAuthenticated(false);
            handleCloseLogout();
            navigate('/');
        } catch (err) {
            console.error("Error during logout", err);
        }
    };

    if (!user) {
        return null;
    }

  return (
    <div className='row m-0 g-0 p-0'>
       <div className='col-3 mt-5'>
            <div className='d-flex flex-column align-items-center mt-1'>
                <Link className='custom-product-btn btn my-1 text-start op-selected rounded rounded-3 d-flex align-items-center' to="/profile"><Icon.PersonCircle size={28}/><span className='ms-2'> Profile</span></Link >
                {role === "admin" ?(
                    <Link className='custom-product-btn btn my-1 text-start rounded rounded-3 d-flex align-items-center' to="/order-board"><Icon.BoxSeam size={28}/><span className='ms-2'>Orders</span></Link >
                ):(
                <Link className='custom-product-btn btn my-1 text-start rounded rounded-3 d-flex align-items-center' to="/orders"><Icon.BoxSeam size={28}/><span className='ms-2'>Orders</span></Link >
                )}

                <div className="row g-0 mx-3 justify-content-center dotted-separator-2">
                    <div className="col-auto">
                    </div>
                </div>
                <Link className='custom-product-btn btn my-1 text-start rounded rounded-3 d-flex align-items-center' onClick={handleShowLogout}><Icon.BoxArrowInRight size={28}/><span className='ms-2'>Sign out</span></Link >
            </div>
        </div>
        <div className="col-8">
            <div className="row g-0 justify-content-between">
                <div className='col-auto mt-3'>
                    <span className='form-title ms-4'>
                        My data
                    </span>
                </div>
            </div>
            <div className="row g-0 mt-4 justify-content-end">
                <div className='col-3 me-5 d-flex justify-content-start'>
                    <span className='required-fields'>
                        *Required fields
                    </span>
                </div>
            </div>
            <form onSubmit={handleUpdateProfile}>
                <div className="row g-0 mt-2">
                    <div className='col-3 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            *Firstname
                        </span>
                        <input type="text" name="name" id="name" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.name} onChange={handleChange}/>
                    </div>
                    <div className='col-3 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            *Last name
                        </span>
                        <input type="text" name="last_name" id="last_name" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.last_name} onChange={handleChange} />
                    </div>
                    <div className='col-3 d-flex flex-column'>
                        <span className='ms-2'>
                            *Surname
                        </span>
                        <input type="text" name="surname" id="surname" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.surname} onChange={handleChange}/>
                    </div>
                </div>
                <div className="row g-0 mt-4">
                    <div className='col-3 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            Pronouns
                        </span>
                        <input type="text" name="pronouns" id="pronouns" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.pronouns} onChange={handleChange}/>
                    </div>
                    <div className='col-3 d-flex flex-column'>
                        <span className='ms-2'>
                            Phone
                        </span>
                        <input type="text" name="phone" id="phone" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.phone} onChange={handleChange}/>
                    </div>
                </div>
                <div className="row g-0 justify-content-end mt-5 me-3">
                    <div className='col-4 d-flex justify-content-start'>
                        <input type='submit' className='btn basket-checkout-btn rounded rounded-3' value='Save'/>
                    </div>
                </div>
            </form>
            <div className="row g-0 mt-2 ms-5">
                <div className="col-9 separator-2">
                </div>
            </div>
            <form onSubmit={handleUpdateEmail}>
                <div className="row g-0 justify-content-between mt-4">
                    <div className='col-auto mt-3'>
                        <span className='form-title'>
                            Email
                        </span>
                    </div>
                </div>
                <div className="row g-0 mt-3">
                    <div className='col-3 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            Current email
                        </span>
                        <input type="text" name="email" id="email" className='textfield-small rounded rounded-3 p-2 ps-3 disabled' value={user.email} disabled/>
                    </div>
                    <div className='col-3 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            *New email
                        </span>
                        <input type="text" name="new_email" id="new_email" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.new_email} onChange={handleChange}/>
                    </div>
                    <div className='col-3 d-flex flex-column'>
                        <span className='ms-2'>
                            *Password
                        </span>
                        <input type="password" name="password" id="password" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.password} onChange={handleChange}/>
                    </div>
                </div>
                <div className="row g-0 justify-content-end mt-5 me-3">
                    <div className='col-4 d-flex justify-content-start'>
                        <input type='submit' className='btn basket-checkout-btn rounded rounded-3' value='Save'/>
                    </div>
                </div>
            </form>
            <div className="row g-0 mt-2 ms-5">
                <div className="col-9 separator-2">
                </div>
            </div>
            <form onSubmit={handleUpdatePassword}>
                <div className="row g-0 justify-content-between mt-4">
                    <div className='col-auto mt-3'>
                        <span className='form-title'>
                            Password
                        </span>
                    </div>
                </div>
                <div className="row g-0 mt-3">
                    <div className='col-3 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            *Current password
                        </span>
                        <input type="password" name="current_password" id="current_password" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.current_password} onChange={handleChange}/>
                    </div>
                    <div className='col-3 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            *New password
                        </span>
                        <input type="password" name="new_password" id="new_password" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.new_password} onChange={handleChange} />
                    </div>
                    <div className='col-3 d-flex flex-column'>
                        <span className='ms-2'>
                            *Repeat new password
                        </span>
                        <input type="password" name="new_password_confirmation" id="new_password_confirmation" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.new_password_confirmation} onChange={handleChange}/>
                    </div>
                </div>
                <div className="row g-0 justify-content-end mt-5 me-3 mb-5">
                    <div className='col-4 d-flex justify-content-start'>
                        <input type='submit' className='btn basket-checkout-btn rounded rounded-3' value='Save'/>
                    </div>
                </div>
            </form>
            <Modal show={showSuccess} onHide={handleCloseSuccess} centered className="g-0">
    <div className='order-details-modal px-2 py-3 rounded rounded-3'>
        <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
                <Icon.XLg size={32} onClick={handleCloseSuccess}  style={{ cursor: 'pointer' }}/>
            </div>
        </div>
        <div className="row g-0 mt-1 justify-content-center">
            <div className="col-auto">
                <h1 className="modal-title fs-2 " id="exampleModalLabel">Success!</h1>
            </div>
        </div>
        <div className="row g-0 mt-4 mb-5 justify-content-center">
            <div className="col-9 mt-3 d-flex flex-column">
                <span className='ms-2'>Your information was successfully updated</span>
            </div>
        </div>
        <div className="row justify-content-center mt-4 mb-3">
            <div className='col-auto'>
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseSuccess}/>
            </div>
        </div>
        </div>
    </Modal>
    <Modal show={showError} onHide={handleCloseError} centered className="g-0">
    <div className='order-details-modal px-2 py-3 rounded rounded-3'>
        <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
                <Icon.XLg size={32} onClick={handleCloseError}  style={{ cursor: 'pointer' }}/>
            </div>
        </div>
        <div className="row g-0 mt-1 justify-content-center">
            <div className="col-auto">
                <h1 className="modal-title fs-2 " id="exampleModalLabel">ERROR</h1>
            </div>
        </div>
        <div className="row g-0 mt-4 mb-5 justify-content-center">
            <div className="col-9 mt-3 d-flex justify-content-center">
                {error && (
                    <span className='ms-2'>{error}</span>
                )}
            </div>
        </div>
        <div className="row justify-content-center mt-4 mb-3">
            <div className='col-auto'>
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseError}/>
            </div>
        </div>
        </div>
    </Modal>
    <Modal show={showLogout} onHide={handleCloseLogout} centered className="g-0">
       <div className='order-details-modal px-2 py-3 rounded rounded-3'>
           <div className='row g-0 justify-content-end me-2'>
               <div className='col-auto'>
                   <Icon.XLg size={32} onClick={handleCloseLogout}  style={{ cursor: 'pointer' }}/>
               </div>
           </div>
           <div className="row g-0 mt-1 justify-content-center">
               <div className="col-auto">
                   <h1 className="modal-title fs-2 " id="exampleModalLabel">Logout</h1>
               </div>
           </div>
           <div className="row g-0 mt-4 mb-5 justify-content-center">
               <div className="col-9 mt-3 d-flex justify-content-center">

                       <span className='ms-2'>You are about to log out, are you sure?</span>

               </div>
           </div>
           <div className="row justify-content-center mt-4 mb-3">
               <div className='col-10 d-flex justify-content-between'>
                <input type='button' className='btn order-details-btn order-btn rounded rounded-3' value='Cancel' onClick={handleCloseLogout} />
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleLogout} />
               </div>
           </div>
           </div>
       </Modal>
        </div>
    </div>
  )
}
