import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link} from 'react-router-dom';

export const Receipt = () => {
  const location = useLocation();
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const orderCode = location.state?.orderCode;

  useEffect(() => {
    if (orderCode == null) {
      navigate('/');
    } else {
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      setCartItems(storedCartItems);
    }
  }, [orderCode, navigate, location.state]);


  const role = localStorage.getItem('role');
  useEffect(() => {
    if (cartItems.length > 0) {
      localStorage.removeItem('cartItems');
    }
  }, [cartItems]);

  return (
    <div className='catalog row m-0 g-0 p-0 justify-content-center'>
      <div className='col-auto form-title mt-3'>
        <span>Order completed</span>
      </div>
      <div className='row mt-5 justify-content-center'>
        <div className='col-6'>
          {role ? (
            <span className='fs-4'>
              Your order {orderCode} was placed successfully, you can check the status of your order on Orders.
            </span>
          ):(
            <span className='fs-4'>
              Your order {orderCode} was placed successfully, we will keep you updated via email.
            </span>
          )}
        </div>
        <div className="row g-0 mt-4 justify-content-center">
          <div className="col-6 mb-1 separator-2"></div>
        </div>
        {cartItems.map((item, index) => (
          <div key={index} className="row g-0 mt-3 justify-content-center">
            <div className="col-5 d-flex justify-content-between ms-4">
              <div className="product-image-basket rounded rounded-4">
                <img src={`https://smollnia-api.shop/public/${item.image}`} alt={item.name} className="preview-image product-image-basket rounded rounded-4" />
              </div>
              <div className="product-basket-details d-flex align-items-center ms-4">
                <span className='product-quantity-basket'>{item.name}</span>
              </div>
              <div className="product-basket-details d-flex align-items-center ms-4">
                <span className='product-quantity-basket'>Quantity: {item.quantity}</span>
              </div>
            </div>
          </div>
        ))}
        <div className="row g-0 mt-1 justify-content-center">
          <div className="col-6 separator-2"></div>
        </div>
        {role ? (
          <div className="row g-0 mt-3 justify-content-center">
          <div className="col-auto">
            <Link to={"/orders"} className='btn order-checkout-btn px-3'>Check your order</Link>
          </div>
        </div>
        ):(null)

        }

      </div>
    </div>
  );
}
