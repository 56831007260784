import React, { useContext, useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import * as Icon from 'react-bootstrap-icons';
import { AuthContext } from '../../App';


const LoginModal = ({ show, handleClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const { setIsAuthenticated } = useContext(AuthContext);

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://smollnia-api.shop/api/login', {
                email: email,
                password: password
            });
            const token = response.data.token.plainTextToken;
            localStorage.setItem('authToken', token);
            const role = response.data.data.role;
            localStorage.setItem('role', role);
            setIsAuthenticated(true);
            handleClose(true);
        } catch (err) {
            setError('Login failed. Please check your credentials.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body className="custom-modal rounded rounded-3">
                <div className='row justify-content-end me-2'>
                    <div className='col-auto'>
                        <Icon.XLg size={32} onClick={handleClose}  style={{ cursor: 'pointer' }}/>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-auto'>
                        <span className='fs-2'>Sign in</span>
                    </div>
                </div>
                <div className="row g-0 mt-3 justify-content-center">
                    <div className="col-9 d-flex flex-column">
                        <span className='ms-2'>*Email</span>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className='textfield-login rounded rounded-3 p-2 ps-3'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row g-0 mt-3 justify-content-center">
                    <div className="col-9 d-flex flex-column">
                        <span className='ms-2'>*Password</span>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className='textfield-login rounded rounded-3 p-2 ps-3'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                {error && (
                    <div className="row g-0 mt-3 justify-content-center">
                        <div className="col-9 d-flex flex-column">
                            <div className="alert alert-danger">{error}</div>
                        </div>
                    </div>
                )}
                <div className="row g-0 mx-5 mt-1 justify-content-between">
                    <div className='col-auto ms-3 d-flex align-items-center'>
                        <input type="checkbox" name="remember-me" id="remember-me" className='remember-me me-1' />
                        <span className='mt-1 remember-me-text'>
                            Remember me
                        </span>
                    </div>
                    <div className='col-auto me-3'>
                        <a className='has-account mt-1' href='/password-recover'>
                            Forgot your password?
                        </a>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className='col-4 d-flex justify-content-center'>
                        <input
                            type='button'
                            className='btn login-btn rounded rounded-3'
                            value='Sign in'
                            onClick={handleLogin}
                        />
                    </div>
                </div>
                <div className="row g-0 my-4 mx-5 justify-content-center separator"></div>
                    <div className="row g-0 justify-content-center">
                        <div className='col-auto'>
                            <span className='new-account mt-1'>
                                New here? Create an account!
                            </span>
                        </div>
                    </div>
                <div className="row justify-content-center mt-2 mb-4">
                    <div className='col-4 d-flex justify-content-center'>
                         <a className='btn login-btn rounded rounded-3' href='/register'>Register</a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;