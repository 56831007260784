import {React, useState} from 'react';
import axiosInstance from '../Interceptor/axiosConfig';
import { Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export const Feedback = () => {
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [showError, setShowError] = useState(false);
  const handleCloseError = () => setShowError(false);
  const handleShowError = () => setShowError(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    messages: '',
    pronouns: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/send-feedback-email', formData);
      if (response.data.success) {
        handleShowSuccess();
        setFormData({
          name: '',
          email: '',
          messages: '',
          pronouns: ''
        });
      }
    } catch (error) {
      setError("The required fields must be filled.")
      handleShowError();
    }
  };
  return (
    <div>
    <form onSubmit={handleSubmit}>
    <div className='catalog row m-0 g-0 p-0 justify-content-center'>
      <div className='col-auto form-title mt-3'>
      <span>Found any bugs? Let me know!</span>
      </div>
      <div className='row mt-5 justify-content-center'>
        <div className='col-2 d-flex flex-column'>
          <span className='ms-2'>
            *Firstname
          </span>
          <input type="text" name="name" id="name" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.name} onChange={handleChange} />
        </div>
        <div className='col-2 d-flex flex-column'>
          <span className='ms-2'>
            Pronouns
          </span>
          <input type="text" name="pronouns" id="pronouns" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.pronouns} onChange={handleChange} />
        </div>
        <div className="row justify-content-center mt-4">
          <div className='col-4 d-flex flex-column'>
            <span className='ms-2'>
              *Email
            </span>
            <input type="email" name="email" id="email" className='textfield-small rounded rounded-3 p-2 ps-3' value={formData.email} onChange={handleChange}/>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className='col-4 d-flex flex-column'>
            <span className='ms-2'>
              *Message
            </span>
            <textarea name="messages" id="messages" className='textarea rounded rounded-3 p-2 ps-3' value={formData.messages} onChange={handleChange} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className='col-4 d-flex justify-content-end'>
            <span className='required-fields mt-1 me-2'>
              *Required fields
            </span>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className='col-4 d-flex justify-content-center'>
            <input type='submit' className='btn basket-checkout-btn rounded rounded-3' value='Send'/>
          </div>
        </div>
      </div>
    </div>
    </form>
       <Modal show={showSuccess} onHide={handleCloseSuccess} centered className="g-0">
       <div className='order-details-modal px-2 py-3 rounded rounded-3'>
           <div className='row g-0 justify-content-end me-2'>
               <div className='col-auto'>
                   <Icon.XLg size={32} onClick={handleCloseSuccess}  style={{ cursor: 'pointer' }}/>
               </div>
           </div>
           <div className="row g-0 mt-1 justify-content-center">
               <div className="col-auto">
                   <h1 className="modal-title fs-2 " id="exampleModalLabel">Success!</h1>
               </div>
           </div>
           <div className="row g-0 mt-4 mb-5 justify-content-center">
               <div className="col-auto mt-3 d-flex justify-content-center">
                   <span className='ms-2'>Email sent successfully, we will reply as fast as posible.</span>
               </div>
           </div>
           <div className="row justify-content-center mt-4 mb-3">
               <div className='col-auto'>
                   <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseSuccess}/>
               </div>
           </div>
           </div>
       </Modal>
       <Modal show={showError} onHide={handleCloseError} centered className="g-0">
       <div className='order-details-modal px-2 py-3 rounded rounded-3'>
           <div className='row g-0 justify-content-end me-2'>
               <div className='col-auto'>
                   <Icon.XLg size={32} onClick={handleCloseError}  style={{ cursor: 'pointer' }}/>
               </div>
           </div>
           <div className="row g-0 mt-1 justify-content-center">
               <div className="col-auto">
                   <h1 className="modal-title fs-2 " id="exampleModalLabel">ERROR</h1>
               </div>
           </div>
           <div className="row g-0 mt-4 mb-5 justify-content-center">
               <div className="col-9 mt-3 d-flex justify-content-center">
                   {error && (
                       <span className='ms-2'>{error}</span>
                   )}
               </div>
           </div>
           <div className="row justify-content-center mt-4 mb-3">
               <div className='col-auto'>
                   <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseError}/>
               </div>
           </div>
           </div>
       </Modal>
       </div>
  )
}
