import React, { useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import axiosInstance from '../Interceptor/axiosConfig';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

export const Register = () => {
    const [error, setError] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => setShowSuccess(true);
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        second_name: '',
        pronouns: '',
        email: '',
        phone_number: '',
        password: '',
        password_confirmation: ''
    });
    const [agreePolicies, setAgreePolicies] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newValue = (name === 'phone_number' || name === 'pronouns') && value === '' ? null : value;
        setFormData({ ...formData, [name]: newValue });
    };

    const handleCheckboxChange = (e) => {
        setAgreePolicies(e.target.checked);
    };

    const handleSubmit = async () => {
        if (!agreePolicies) {
            setError('You must agree to the Terms of Service and Privacy Policy');
            handleShowError();
            return;
        }
        try {
            const response = await axiosInstance.post('/register', formData);
            console.log(response.data);
            handleShowSuccess();
        } catch (err) {
            setError('You must fill all the required fields');
            handleShowError();
        }
    };

    return (
        <div className='catalog row m-0 g-0 p-0 justify-content-center'>
            <div className='col-auto form-title mt-3'>
                <span>Create a new account</span>
            </div>
            <div className='row mt-5 justify-content-center'>
                <div className="row g-0 justify-content-center">
                    <div className='col-2 d-flex flex-column me-4'>
                        <span className='ms-2'>
                        *Firstname
                        </span>
                        <input type="text" name="name" id="name" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange} />
                    </div>
                    <div className='col-2 d-flex flex-column'>
                        <span className='ms-2'>
                        *Lastname
                        </span>
                        <input type="text" name="last_name" id="last_name" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange}/>
                    </div>
                </div>
                <div className="row g-0 mt-3 justify-content-center">
                    <div className='col-2 d-flex flex-column me-4'>
                        <span className='ms-2'>
                        *Surname
                        </span>
                        <input type="text" name="surname" id="surname" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange}/>
                    </div>
                    <div className='col-2 d-flex flex-column'>
                        <span className='ms-2'>
                        Pronouns
                        </span>
                        <input type="text" name="pronouns" id="pronouns" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange}/>
                    </div>
                </div>
                <div className="row g-0 justify-content-center mt-3">
                    <div className='col-2 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            *Email
                        </span>
                        <input type="mail" name="email" id="email" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange} />
                    </div>
                    <div className='col-2 d-flex flex-column'>
                        <span className='ms-2'>
                            Phone number
                        </span>
                        <input type="text" name="phone_number" id="phone_number" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange}/>
                    </div>
                </div>
                <div className="row g-0 justify-content-center mt-3">
                    <div className='col-2 d-flex flex-column me-4'>
                        <span className='ms-2'>
                            *Password
                        </span>
                        <input type="password" name="password" id="password" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange} />
                    </div>
                    <div className='col-2 d-flex flex-column'>
                        <span className='ms-2'>
                            *Repeat password
                        </span>
                        <input type="password" name="password_confirmation" id="password_confirmation" className='textfield-small rounded rounded-3 p-2 ps-3' onChange={handleChange} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className='col-5 d-flex justify-content-end'>
                        <span className='required-fields mt-1 me-5'>
                            *Required fields
                        </span>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className='col-5 d-flex align-items-center ms-2'>
                        <input type="checkbox" name="policies" id="policies" className='chb-terms ms-5' onChange={handleCheckboxChange}/>
                        <span className='ms-2'>
                            I have read the <Link to={"/terms-of-service"} className='underline'>Terms of Service</Link> and the <Link to={"/privacy-policy"} className='underline'>Privacy Policy</Link> of this page.
                        </span>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className='col-4 d-flex justify-content-center'>
                        <input type='button' className='btn basket-checkout-btn rounded rounded-3' value='Create account' onClick={handleSubmit}/>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="success-modal" tabIndex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-content-rounded rounded-3 modal-content-success shadow">
                        <div className="modal-body">
                            <div className='row g-0 justify-content-end'>
                                <div className="col-auto">
                                    <button type="button" data-bs-dismiss="modal" className='btn close-btn' aria-label="Close"><Icon.XLg size={28}/></button>
                                </div>
                            </div>
                            <div className="row g-0 justify-content-center">
                                <div className="col-auto">
                                    <h1 className="modal-title fs-4" id="exampleModalLabel">Account created</h1>
                                </div>
                            </div>
                            <div className="row g-0 mt-4 justify-content-center">
                                <div className="col-9 d-flex flex-column">
                                    <span>Your account has been created, check your email to verify your account.</span>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-4 mb-3">
                                <div className='col-4 d-flex justify-content-center mt-2'>
                                    <input type='button' className='btn accept-btn rounded rounded-3' value='Create account' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showError} onHide={handleCloseError} centered className="g-0">
    <div className='order-details-modal px-2 py-3 rounded rounded-3'>
        <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
                <Icon.XLg size={32} onClick={handleCloseError}  style={{ cursor: 'pointer' }}/>
            </div>
        </div>
        <div className="row g-0 mt-1 justify-content-center">
            <div className="col-auto">
                <h1 className="modal-title fs-2 " id="exampleModalLabel">ERROR</h1>
            </div>
        </div>
        <div className="row g-0 mt-4 mb-5 justify-content-center">
            <div className="col-auto mt-3 d-flex justify-content-center">
                {error && (
                    <span className='ms-2'>{error}</span>
                )}
            </div>
        </div>
        <div className="row justify-content-center mt-4 mb-3">
            <div className='col-auto'>
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseError}/>
            </div>
        </div>
        </div>
    </Modal>
    <Modal show={showSuccess} onHide={handleCloseSuccess} centered className="g-0">
    <div className='order-details-modal px-2 py-3 rounded rounded-3'>
        <div className='row g-0 justify-content-end me-2'>
            <div className='col-auto'>
                <Icon.XLg size={32} onClick={handleCloseSuccess}  style={{ cursor: 'pointer' }}/>
            </div>
        </div>
        <div className="row g-0 mt-1 justify-content-center">
            <div className="col-auto">
                <h1 className="modal-title fs-2 " id="exampleModalLabel">Success!</h1>
            </div>
        </div>
        <div className="row g-0 mt-4 mb-5 justify-content-center">
            <div className="col-auto mt-3 d-flex justify-content-center">
                <span className='ms-2'>User created successfully.</span>
            </div>
        </div>
        <div className="row justify-content-center mt-4 mb-3">
            <div className='col-auto'>
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleCloseSuccess}/>
            </div>
        </div>
        </div>
    </Modal>
        </div>
    )
}
