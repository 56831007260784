import {React, useState} from 'react';
import aboutme from '../../img/aboutme.png';
import aboutme2 from '../../img/aboutme2.png';

export const AboutMe = () => {
  const [imageSrc, setImageSrc] = useState(aboutme);

  const handleOnClick = () => {
    if(imageSrc === aboutme){
      setImageSrc(aboutme2);
    }
    else{
      setImageSrc(aboutme);
    }
  };

  return (
    <div className='row g-0 justify-content-center'>
      <div className="row g-0 justify-content-center mb-4 mt-3">
        <div className="col-auto form-title">
          <span>A bit of myself!</span>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-center">
        <img className="about-me" src={imageSrc} alt=""  onClick={handleOnClick}/>
      </div>
      <div className="col-4 d-flex align-items-center justify-content-center">
        <div className="col-11 d-flex align-content-center mt-1">
        <span className='fs-5'>
        Welcome! My name is Nia, a freelance artist from Barcelona who loves nature, bugs, and drawing cute stuff. I've studied digital illustration for a few years, although I'm learning new stuff on my own for now, such as working on clay figures and different kinds of merch!
On my website, you will find stickers, keychains, or prints, as also hand-made stuff! (made with tons of love) I hope you like my art. Thanks a lot for supporting me!
        </span>
        </div>

      </div>
    </div>
  )
}

