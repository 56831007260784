import '../../index.css';
import worm from '../../img/worm.png';
import * as Icon from 'react-bootstrap-icons';
import { Link, useNavigate } from "react-router-dom";
import LoginModal from '../UserLogin/LoginModal';
import React, { useState, useContext, useEffect } from 'react';
import axiosInstance from '../Interceptor/axiosConfig';
import { AuthContext } from '../../App';
import BasketModal from './BasketModal';
import { Modal } from 'react-bootstrap';


export const Navbar = () => {
    const [activeLink, setActiveLink] = useState('/');
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showBasketModal, setShowBasketModal] = useState(false);
    const { isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({ name: '', last_name: '', surname: '' });
    const [showLogout, setShowLogout] = useState(false);
    const handleCloseLogout = () => setShowLogout(false);
    const handleShowLogout = () => setShowLogout(true);

    const role = localStorage.getItem('role');
    const handleOpenModal = () => setShowLoginModal(true);

    useEffect(()=>{
        checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAuthentication = async () => {
        try {
            const response = await axiosInstance.get('/user/details');
            if (response.data.user) {
                setIsAuthenticated(true);
                setUserInfo({
                    name: response.data.user.name,
                    last_name: response.data.user.last_name,
                    surname: response.data.user.surname
                });
            } else {
                setIsAuthenticated(false);
            }
        } catch (err) {
            setIsAuthenticated(false);
        }
    };

    const handleCloseModal = (setNavbarStatus) => {
        setShowLoginModal(false);
        checkAuthentication();
        if(setNavbarStatus !== undefined){
            setIsAuthenticated(setNavbarStatus);
        }
    }

    const handleBasketModalClose = () => setShowBasketModal(false);
    const handleBasketModalShow = () => setShowBasketModal(true);

    const handleLogout = async () => {
        try {
            await axiosInstance.post('/logout');
            localStorage.removeItem('authToken');
            localStorage.removeItem('role');
            setIsAuthenticated(false);
            setUserInfo({ name: '', last_name: '', lastname: '' });
            handleCloseLogout();
            navigate("/");
        } catch (err) {
            console.error("Error logging", err);
        }
    };

    const handleLinkClick = (path) => {
        setActiveLink(path);
      };

  return (
        <div className='navbar-header'>
            <div className='row g-0 justify-content-center'>
                <div className='col-5 d-flex flex-wrap justify-content-center mt-4'>
                    <div>
                        <Link  className={`btn custom-navbar-btn ${activeLink === '/' ? 'op-selected' : ''} rounded rounded-3 mx-2`}
                            to="/"
                            role="button"
                            onClick={() => handleLinkClick('/')}>Catalog
                        </Link>
                        {role !== "admin" ? (

                             <Link className={`btn custom-navbar-btn ${activeLink === '/aboutme' ? 'op-selected' : ''} rounded rounded-3 mx-2`}
                             to="/aboutme"
                             role="button"
                             onClick={() => handleLinkClick('/aboutme')}>About me
                         </Link>

                        ):(
                       null
                        )}
                        {role !== "admin" ? (

                            <Link  className={`btn custom-navbar-btn ${activeLink === '/contact' ? 'op-selected' : ''} rounded rounded-3 mx-2`}
                             to="/contact"
                             role="button"
                             onClick={() => handleLinkClick('/contact')}>Contact
                         </Link>
                       ):(
                      null
                       )}

                    </div>
                    <div className='d-flex btn-options flex-wrap'>
                    {isAuthenticated ? (
                        <div className="dropdown">
                            <button className="btn dropdown-toggle user-menu-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><Icon.Person size={32} /></button>
                            <ul className="dropdown-menu">
                                <li><span className="dropdown-item py-2">{userInfo.name} {userInfo.last_name} {userInfo.surname}</span></li>
                                <li><div className="d-flex justify-content-center"><div className="dotted-separator-4"></div></div></li>
                                <li><div className="d-flex align-items-center ms-4 pt-2 pb-1 dropdown-op"><Icon.PersonCircle size={28}/><Link className="dropdown-item" to="/profile">Profile</Link></div></li>
                                {role !== "admin" ? (
                                    <li><div className="d-flex align-items-center ms-4 pt-1 pb-2 dropdown-op"><Icon.BoxSeam size={28}/><Link className="dropdown-item" to="/orders">Orders</Link></div></li>
                                ) : (
                                    <li><div className="d-flex align-items-center ms-4 pt-1 pb-2 dropdown-op"><Icon.BoxSeam size={28}/><Link className="dropdown-item" to="/order-board">Orders</Link></div></li>
                                )}
                                <li><div className="d-flex justify-content-center"><div className="dotted-separator-4"></div></div></li>
                                <li><div className="d-flex align-items-center ms-4 py-2 dropdown-op"><Icon.BoxArrowInRight size={28}/><button className="dropdown-item" onClick={handleShowLogout}>Sign out</button></div></li>
                            </ul>
                        </div>
                     ) : (
                        <Link
                            className={`btn custom-navbar-btn d-flex align-items-center icon-option rounded rounded-3 mx-2`}
                            onClick={() => { handleOpenModal(); handleLinkClick('login'); }}>
                            <Icon.Person size={32} />
                        </Link>
                    )}
                    <LoginModal show={showLoginModal} handleClose={handleCloseModal} />
                    {role !== "admin" ?(
                        <Link className="btn custom-navbar-btn d-flex align-items-center icon-option rounded rounded-3 mx-2" onClick={() => { handleBasketModalShow()}}><Icon.Cart4 size={32}/></Link>
                    ):(
                        null
                    )
                    }

                    </div>
                </div>
            </div>
            <div className='worm d-flex justify-content-center mb-2'>
                <img src={worm} alt='Worm' />
            </div>
            <BasketModal show={showBasketModal} handleClose={handleBasketModalClose} />
            <Modal show={showLogout} onHide={handleCloseLogout} centered className="g-0">
       <div className='order-details-modal px-2 py-3 rounded rounded-3'>
           <div className='row g-0 justify-content-end me-2'>
               <div className='col-auto'>
                   <Icon.XLg size={32} onClick={handleCloseLogout}  style={{ cursor: 'pointer' }}/>
               </div>
           </div>
           <div className="row g-0 mt-1 justify-content-center">
               <div className="col-auto">
                   <h1 className="modal-title fs-2 " id="exampleModalLabel">Logout</h1>
               </div>
           </div>
           <div className="row g-0 mt-4 mb-5 justify-content-center">
               <div className="col-9 mt-3 d-flex justify-content-center">

                       <span className='ms-2'>You are about to log out, are you sure?</span>

               </div>
           </div>
           <div className="row justify-content-center mt-4 mb-3">
               <div className='col-10 d-flex justify-content-between'>
                <input type='button' className='btn order-details-btn order-btn rounded rounded-3' value='Cancel' onClick={handleCloseLogout} />
                <input type='button' className='btn order-support-btn order-btn rounded rounded-3' value='Accept' onClick={handleLogout} />
               </div>
           </div>
           </div>
       </Modal>
        </div>
    );
}
