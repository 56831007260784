import {React, useState, useEffect, useContext} from 'react';
import axiosInstance from '../Interceptor/axiosConfig';
import { AuthContext } from '../../App';
import { useNavigate } from 'react-router-dom';

export const Checkout = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setTotal] = useState(0);
  const [userData, setUserData] = useState({
    id: '',
    email: '',
    name: '',
    last_name: '',
    surname: '',
    country: '',
    address: '',
    apartment: '',
    postal_code: '',
    city: '',
    province: ''
  });

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && cartItems.length === 0) {
      navigate('/');
    }
  }, [loading, cartItems, navigate]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axiosInstance.get('/user/details');
        const userData = response.data.user;
        setUserData({
          id: userData.id,
          email: userData.email,
          name: userData.name,
          last_name: userData.last_name,
          surname: userData.surname,
        });
      } catch (err) {
        console.error('Error fetching user profile:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCartItems);
    calculateTotal(storedCartItems);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));
  };

  const handleIncrement = (index) => {
    const newCartItems = [...cartItems];
    const item = newCartItems[index];
    if (item.quantity < item.stock) {
      item.quantity += 1;
      setCartItems(newCartItems);
      localStorage.setItem('cartItems', JSON.stringify(newCartItems));
      calculateTotal(newCartItems);
    }
  };

  const handleDecrement = (index) => {
    const newCartItems = [...cartItems];
    const item = newCartItems[index];

    if (item.quantity > 1) {
      item.quantity -= 1;
    } else {
      newCartItems.splice(index, 1);
    }

    setCartItems(newCartItems);
    localStorage.setItem('cartItems', JSON.stringify(newCartItems));
    calculateTotal(newCartItems);
  };

  const calculateTotal = (items) => {
    const subtotal = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotal(subtotal);
  };

  const shipping = 4;
  const total = subtotal + shipping;

  const handleSubmitOrder = async () => {
    try {
      const orderData = {
        id_user: userData.id,
        name: userData.name,
        last_name: userData.last_name,
        surname: userData.surname,
        email: userData.email,
        total_price: total,
        products: cartItems.map(item => ({
          id_product: item.id,
          quantity: item.quantity,
          payment_method: 'Credit card'
        })),
        country: userData.country,
        address: userData.address,
        apartment: userData.apartment,
        postal_code: userData.postal_code,
        city: userData.city,
        province: userData.province
      };

      const response = await axiosInstance.post('/order', orderData);
      console.log('Order created:', response.data);

      navigate('/receipt', { state: { orderCode: response.data.order.order_code } });

    } catch (error) {
      console.error('Error creating order:', error);

    }
  };

  if (loading) {
    return ;
  }

  return (
    <div className="container-fluid g-0 form-checkout">
      <div className="row g-0 form-checkout">
        <div className="col-6">
          <div className="row g-0 justify-content-end">
            <div className="col-7 mt-3">
              <span className='fs-4'>Contact</span>
            </div>
          </div>
          <div className="row g-0 justify-content-end">
            <div className="col-7 mt-3 d-flex flex-column pe-4">
              <span className='ms-2'>
                *Email
              </span>
              {isAuthenticated ? (
                  <input type="email" name="email" id="email" className='textfield-small rounded rounded-3 p-2 ps-3 disabled' value={userData.email} disabled/> ) : (
                    <input type="email" name="email" id="email" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.email || ""}  onChange={handleChange}/>
                  )}
            </div>
          </div>
          <div className="row g-0 justify-content-end">
            <div className="col-7 mt-4">
              <span className='fs-4'>Delivery</span>
            </div>
          </div>
          <div className="row g-0 justify-content-end ">
            <div className="col-3 mt-3 d-flex flex-column me-2">
              <span className='ms-2'>
                *Country/Region
              </span>
              <input type="text" name="country" id="country" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.country || ""} onChange={handleChange}/>
            </div>
            <div className="col-3 mt-3 d-flex flex-column ms-5 me-4">
              <span className='ms-2'>
                *Firstname
              </span>
              {isAuthenticated ? (
              <input type="text" name="name" id="name" className='textfield-small rounded rounded-3 p-2 ps-3 disabled' value={userData.name} disabled/>) :(
                <input type="text" name="name" id="name" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.name || ""} onChange={handleChange}/>
              )
            }
            </div>
          </div>
          <div className="row g-0 justify-content-end ">
            <div className="col-3 mt-3 d-flex flex-column me-2">
              <span className='ms-2'>
                Last name
              </span>
              {isAuthenticated ? (
              <input type="text" name="last_name" id="last_name" className='textfield-small rounded rounded-3 p-2 ps-3 disabled' value={userData.last_name} disabled/>):(
              <input type="text" name="last_name" id="last_name" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.last_name || ""} onChange={handleChange}/>
              )
            }
            </div>
            <div className="col-3 mt-3 d-flex flex-column ms-5 me-4">
              <span className='ms-2'>
                *Surname
              </span>
              {isAuthenticated ? (
              <input type="text" name="surname" id="surname" className='textfield-small rounded rounded-3 p-2 ps-3 disabled' value={userData.surname} disabled/>):(
              <input type="text" name="surname" id="surname" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.surname || ""} onChange={handleChange}/>
              )
            }
            </div>
          </div>
          <div className="row g-0 justify-content-end">
            <div className="col-7 mt-3 d-flex flex-column pe-4">
              <span className='ms-2'>
                *Address
              </span>
              <input type="text" name="address" id="address" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.address || ""} onChange={handleChange}/>
            </div>
          </div>
          <div className="row g-0 justify-content-end">
            <div className="col-7 mt-3 d-flex flex-column pe-4">
              <span className='ms-2'>
                Apartment, suite, etc.
              </span>
              <input type="text" name="apartment" id="apartment" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.apartment || ""} onChange={handleChange}/>
            </div>
          </div>
          <div className="row g-0 justify-content-end ">
            <div className="col-2 mt-3 d-flex flex-column me-1">
              <span className='ms-2'>
                *Postal code
              </span>
              <input type="text" name="postal_code" id="postal_code" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.postal_code || ""} onChange={handleChange}/>
            </div>
            <div className="col-2 mt-3 d-flex flex-column ms-4 me-1">
              <span className='ms-2'>
                *City
              </span>
              <input type="text" name="city" id="city" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.city || ""} onChange={handleChange}/>
            </div>
            <div className="col-2 mt-3 d-flex flex-column ms-4 me-4">
              <span className='ms-2'>
                *Province
              </span>
              <input type="text" name="province" id="province" className='textfield-small rounded rounded-3 p-2 ps-3' value={userData.province || ""} onChange={handleChange}/>
            </div>
          </div>
          <div className="row g-0 justify-content-end">
            <div className='col-auto'>
              <span className='required-fields mt-1 me-4'>
                *Required fields
              </span>
            </div>
          </div>
          <div className="row g-0 justify-content-end">
            <div className="col-7 mt-5 mb-2 d-flex justify-content-center">
              <input type="button" className='btn order-checkout-btn px-3' value="Place your order" onClick={handleSubmitOrder}/>
            </div>
          </div>
          <div className="row g-0 mb-3 justify-content-end">
            <div className="col-7 mt-4">
            </div>
          </div>
        </div>
        <div className="col-6 total-items">
          <div className="row g-0 justify-content-start">
            <div className="col-auto mt-3 ms-2">
              <span className='fs-4 ms-5'>Your order</span>
            </div>
          </div>
          {cartItems.map((item, index) => (
            <div key={index} className="row g-0 mt-5 mb-3 justify-content-between">
              <div className="col-7 d-flex justify-content-between ms-5">
                <div className="product-image-basket rounded rounded-4">
                  <img src={`https://smollnia-api.shop/public/${item.image}`} alt={item.name} className="preview-image product-image-basket rounded rounded-4" />
                </div>
              <div className="d-flex align-items-center me-5">
                <div className="product-basket-details">
                  <span className='product-name-basket'>{item.name}</span>
                </div>
              </div>
              <div className="d-flex align-items-center product-basket-quantity me-5">
              <input
                  type="button"
                  className="btn btn-product-quantity"
                  value="-"
                  onClick={() => handleDecrement(index)}
                />
                <span className='product-quantity-basket'>{item.quantity}</span>
                <input
                  type="button"
                  className="btn btn-product-quantity"
                  value="+"
                  onClick={() => handleIncrement(index)}
                />
              </div>
              <div className="d-flex align-items-center">
                <span className='product-name-basket'>EUR {item.price * item.quantity}€</span>
              </div>

              </div>
            </div>

          ))}
          <div className="row g-0 justify-content-start">
            <div className="col-7 dotted-separator-2 ms-5"></div>
          </div>
          <div className="row g-0 justify-content-start">
            <div className="col-7 ms-5 mt-2 d-flex justify-content-between">
              <span className='product-name-basket'>Subtotal</span>
              <span className='product-name-basket'>EUR {subtotal.toFixed(2)}€</span>
            </div>
          </div>
          <div className="row g-0 justify-content-start">
            <div className="col-7 ms-5 mt-2 d-flex justify-content-between">
              <span className='product-name-basket'>Shipping</span>
              <span className='product-name-basket'>EUR {shipping.toFixed(2)}€</span>
            </div>
          </div>
          <div className="row g-0 justify-content-start">
            <div className="col-7 ms-5 mt-4 d-flex justify-content-between">
            <span className='product-name-basket'>TOTAL</span>
              <span className='product-name-basket'>EUR {total.toFixed(2)}€</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
