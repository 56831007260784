import React, { useState, useEffect } from 'react';
import banner from '../../img/banner.png';
import axiosInstance from '../Interceptor/axiosConfig';
import { useNavigate, Link } from 'react-router-dom';

const Product = ({ id, name, price, image }) => {
  const urlBase = "https://smollnia-api.shop/public/";
  const productURL = `/product/${id}`;
  const productEdit = `/product-edit/${id}`;
  const navigate = useNavigate();
  const role = localStorage.getItem('role');
  const handleClick = () => {
    if (role === "admin") {
      navigate(productEdit);
    } else {
      navigate(productURL);
    }
  };
  return (
    <div className='product-miniature mt-5 mx-3' onClick={handleClick}>
      <img src={`${urlBase}${image}`} className='product-miniature-image rounded rounded-4' alt='' onClick={handleClick} />
      <div className='product-miniature-details flex-column m-2'>
        <div className='product-miniature-name ms-2'>
          <span>
            {name}
          </span>
        </div>
        <div className='product-miniature-price ms-2'>
          <span>
            {price}
          </span>
        </div>
      </div>
    </div>
  );
}

export const Catalog = () => {
  const [products, setProducts] = useState([]);
  const [selectedType, setSelectedType] = useState('All products');
  const [searchQuery, setSearchQuery] = useState('');
  const role = localStorage.getItem('role');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get('/product/all');
        setProducts(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleFilterClick = (type) => {
    setSelectedType(type);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProducts = products.filter(product => {
    const matchesType = selectedType === 'All products' || product.type === selectedType;
    const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesType && matchesSearch;
  });

  return (
    <div className='catalog row m-0 g-0 p-0'>
      <div className='d-flex justify-content-center'>
        <img className='banner-img rounded rounded-4 shadow' src={banner} alt='Banner' />
      </div>
      <div className='col-3 mt-5'>
        <div className='d-flex flex-column align-items-center mt-1'>
          <button onClick={() => handleFilterClick('All products')} className={`custom-product-btn btn my-1 text-start rounded rounded-3 ${selectedType === 'All products' ? 'op-selected' : ''}`}><span className='ms-4'>All products</span></button>
          <button onClick={() => handleFilterClick('sticker')} className={`custom-product-btn btn my-1 text-start rounded rounded-3 ${selectedType === 'sticker' ? 'op-selected' : ''}`}><span className='ms-4'>Stickers</span></button>
          <button onClick={() => handleFilterClick('keychain')} className={`custom-product-btn btn my-1 text-start rounded rounded-3 ${selectedType === 'keychain' ? 'op-selected' : ''}`}><span className='ms-4'>Keychains</span></button>
          <button onClick={() => handleFilterClick('pin')} className={`custom-product-btn btn my-1 text-start rounded rounded-3 ${selectedType === 'pin' ? 'op-selected' : ''}`}><span className='ms-4'>Pins</span></button>
          <button onClick={() => handleFilterClick('standee')} className={`custom-product-btn btn my-1 text-start rounded rounded-3 ${selectedType === 'standee' ? 'op-selected' : ''}`}><span className='ms-4'>Standees</span></button>
          <button onClick={() => handleFilterClick('washi tape')} className={`custom-product-btn btn my-1 text-start rounded rounded-3 ${selectedType === 'washi tape' ? 'op-selected' : ''}`}><span className='ms-4'>Washi tapes</span></button>
          <button onClick={() => handleFilterClick('print')} className={`custom-product-btn btn my-1 text-start rounded rounded-3 ${selectedType === 'print' ? 'op-selected' : ''}`}><span className='ms-4'>Prints</span></button>
        </div>
      </div>
      <div className='col-7 mt-5 ms-5 mb-5'>
        <div className='catalog-content mt-1'>
          <div className='search-component d-flex justify-content-center'>
            <input
              className='custom-search-bar rounded rounded-3 p-2 ps-3 me-5'
              type='text'
              placeholder='Search'
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {role === "admin" ? (
              <Link className="btn btn-lg op-selected ms-5" to="/product-create">Create product</Link>
            ) : (
              null
            )}
          </div>
          <div className='catalog-items d-flex flex-wrap mx-auto'>
            {filteredProducts.map((product) => (
              <Product key={product.id} id={product.id} name={product.name} price={"EUR " + product.price + "€"} image={product.image} />
            ))}
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
